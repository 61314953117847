<template>
  <div>
    <div v-if="this.isAuthenticated">
      <KTCard ref="preview" v-bind:title="title" v-bind:example="true">
        <template v-slot:title>
          {{ title }}
        </template>
        <template v-slot:body>
        <h6>{{ $t("AUTH.USER_PROFILE.USER_INFO") }}</h6>
          <br />
          <br>
          <br>

          <p align="right">
              <button class="btn btn-primary mx-5" v-if="editmode === false" v-on:click="goToChangePass()">{{ $t("AUTH.USER_PROFILE.BTN_CHANGE_PASS") }}</button>
              <button class="btn btn-primary" v-if="editmode === false" v-on:click="goToEditmode()">{{ $t("AUTH.USER_PROFILE.BTN_CHANGE_EDIT") }}</button>
          </p>


          <h6>{{ $t("AUTH.USER_PROFILE.PICTURE") }}</h6>
          <br>

          <div id="app">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-6 col-12">
                <p align="center">
                  <span id="preview">
                    <img v-if="url" :src="url" height="200px" width="150px" />
                    <img src="media/image/defaultpp3.png" v-if="!url" height="200px" width="150px" />
                  </span><br><br>
    <!--              <input type="file" @change="onFileChange" v-if="editmode === true" />-->
                  <b-form-file
                      id="profileimage"
                      name="profileimage"
                      v-model="$v.form.profileimage.$model"
                      v-bind:disabled="editmode === false"
                      @change="onFileChange"
                      aria-describedby="profileimage-live-feedback"
                      placeholder="Foto Profile"
                  >
                  </b-form-file>
                </p>
                </div>
              </div>
            </div>

          </div>
          
          <br>

          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="row">
            <div class="col-md-6">
                <b-form-group
                id="fullName-group"
                label="Nama Lengkap"
                label-for="fullName"
                >
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    id="fullName"
                    name="fullName"
                    v-model="$v.form.fullName.$model"
                    v-bind:disabled="editmode === false"
                    :state="validateState('fullName')"
                    aria-describedby="fullName-live-feedback"
                    placeholder="Nama Lengkap"
                ></b-form-input>

                <b-form-invalid-feedback id="fullName-live-feedback">
                    Masukkan Nama Lengkap Anda.
                </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                id="birthPlace-group"
                label="Tempat Lahir"
                label-for="birthPlace"
                >
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    id="birthPlace"
                    name="birthPlace"
                    v-model="$v.form.birthPlace.$model"
                    v-bind:disabled="editmode === false"
                    :state="validateState('birthPlace')"
                    aria-describedby="birthPlace-live-feedback"
                    placeholder="Tempat Lahir"
                ></b-form-input>

                <b-form-invalid-feedback id="birthPlace-live-feedback">
                    Masukkan kota tempat lahir.
                </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                id="dateBirth-group"
                label="Tanggal Lahir"
                label-for=""
                >
                <b-form-datepicker
                    id="dateBirth"
                    v-model="$v.form.birthday.$model"
                    v-bind:disabled="editmode === false"
                    :state="validateState('birthday')"
                    aria-describedby="birthPlace-live-feedback"
                    placeholder="Tanggal Lahir"
                    class="form-control form-control-solid h-auto py-5 px-6"
                ></b-form-datepicker>
                <b-form-invalid-feedback id="birthday-live-feedback">
                    Masukkan tanggal lahir.
                </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="education-group" label="Pendidikan" label-for="">
                <b-form-select
                    v-model="$v.form.education.$model"
                    v-bind:disabled="editmode === false"
                    :state="validateState('education')"
                    :options="educations"
                    class="form-control form-control-solid h-auto py-5 px-6"
                    value-field="value"
                    text-field="description"
                    disabled-field="notEnabled"
                    placeholder="Pendidikan"
                >
                    <template #first>
                    <b-form-select-option :value="null"
                        >-- Please select an option --</b-form-select-option
                    >
                    </template>
                </b-form-select>
                </b-form-group>
                <b-form-group id="gender-group" label="Jenis Kelamin" label-for="">
                <b-form-select
                    v-model="$v.form.gender.$model"
                    v-bind:disabled="editmode === false"
                    :state="validateState('gender')"
                    :options="gender"
                    class="form-control form-control-solid h-auto py-5 px-6"
                    value-field="value"
                    text-field="description"
                    disabled-field="notEnabled"
                >
                    <template #first>
                    <b-form-select-option :value="null"
                        >-- Please select an option --</b-form-select-option
                    >
                    </template>
                </b-form-select>
                </b-form-group>
                <b-form-group id="email-group" label="Email" label-for="email">
                  <b-form-input
                      class="form-control form-control-solid h-auto py-5 px-6"
                      id="email"
                      name="email"
                      v-model="$v.form.email.$model"
                      v-bind:disabled="editmode === false"
                      :state="validateState('email')"
                      aria-describedby="email-live-feedback"
                      placeholder="Email address"
                  ></b-form-input>

                  <b-form-invalid-feedback id="email-live-feedback">
                    Email is required and a valid email address.
                  </b-form-invalid-feedback>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group
                id="identity-no-group"
                label-for=""
                label="No Kartu Identitas"
                >
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    id="identityNo"
                    name="identityNo"
                    v-model="$v.form.identityNo.$model"
                    v-bind:disabled="editmode === false"
                    :state="validateState('identityNo')"
                    aria-describedby="birthPlace-live-feedback"
                    placeholder="No Identitas"
                ></b-form-input>
                </b-form-group>
                <b-form-group
                id="identity-type-group"
                label="Tipe Identitas"
                label-for=""
                >
                <b-form-select
                    v-model="$v.form.identityType.$model"
                    v-bind:disabled="editmode === false"
                    :state="validateState('identityType')"
                    :options="identityType"
                    class="form-control form-control-solid h-auto py-5 px-6"
                    value-field="value"
                    text-field="description"
                    disabled-field="notEnabled"
                >
                    <template #first>
                    <b-form-select-option :value="null"
                        >-- Please select an option --</b-form-select-option
                    >
                    </template>
                </b-form-select>
                </b-form-group>
                <b-form-group
                id="idimage-group"
                label-for=""
                label="Foto Kartu Identitas"
                >
                <span id="previewIdentitas">
                  <img v-if="pathIdentity != ''" :src="createDownloadUrl(pathIdentity)" height="150px" width="150px" />
                  <img src="media/image/defaultpp3.png" v-if="pathIdentity === ''" height="150px" width="150px" />
                </span>
                <b-form-file
                    id="idimage"
                    name="idimage"
                    v-model="$v.form.idimage.$model"
                    v-bind:disabled="editmode === false"
                    :state="validateState('idimage')"
                    aria-describedby="idimage-live-feedback"
                    placeholder="Foto identitas"
                >
                </b-form-file>
                </b-form-group>
                <b-form-group id="phone-group" label-for="" label="No Telepon">
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    id="phone"
                    name="phone"
                    v-model="$v.form.phone.$model"
                    v-bind:disabled="editmode === false"
                    :state="validateState('birthPlace')"
                    aria-describedby="phone-live-feedback"
                    placeholder="No Telpon"
                ></b-form-input>
                </b-form-group>
                <b-form-group id="address-group" label="Alamat" label-for="">
                <b-form-textarea
                    class="form-control form-control-solid h-auto py-5 px-6"
                    id="address"
                    name="address"
                    v-model="$v.form.address.$model"
                    v-bind:disabled="editmode === false"
                    :state="validateState('address')"
                    aria-describedby="address-live-feedback"
                    placeholder="Alamat Domisili"
                ></b-form-textarea>
                </b-form-group>
            </div>
            </div>

            <div class="row">
              <!--begin::Action-->
              <div class="form-group d-flex flex-wrap flex-center">
                <button
                  class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                  v-if="editmode === true"
                  ref="kt_login_signup_submit"
                >
                  Submit
                </button>
                <button
                  class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                  v-on:click="cancelEdit()"
                  v-if="editmode === true"
                >
                  Cancel
                </button>
                <!--end::Action-->
              </div>
            </div>
        </b-form>


        <b-modal ref="my-modal" size="sm">
          <div class="d-block text-center">
            <h3>
              {{ $t("AUTH.USER_PROFILE.SUBMIT_EDIT") }}
            </h3>
          </div>
          <template #modal-footer="{  }">
            <b-button size="lg" variant="danger" @click="hideModal()">
              TUTUP
            </b-button>
          </template>
        </b-modal>

        <b-modal ref="my-modal2" size="sm">
          <div class="d-block text-center">
            <h3>
              {{ $t("AUTH.USER_PROFILE.ERROR_EDIT") }}
            </h3>
          </div>
          <template #modal-footer="{ close }">
            <b-button size="lg" variant="danger" @click="close()">
              TUTUP
            </b-button>
          </template>
        </b-modal>
      
        </template>
      </KTCard>
    </div>
    <div v-if="!this.isAuthenticated">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-body d-flex p-0">
          <div
            class="flex-grow-1 p-20 pb-40 card-rounded flex-grow-1 bgi-no-repeat"
            style="background-position: calc(100% + 0.5rem) bottom; background-size: 35% auto; background-image: url(media/svg/humans/custom-10.svg)"
          >
            <h2 class="text-dark pb-5 font-weight-bolder">
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_TITLE") }}
            </h2>
            <p class="text-dark-50 pb-5 font-size-h5">
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW") }}<br />
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_SECOND_PARAGRAPH") }}
            </p>
            <a
              @click="registerClicked"
              class="btn btn-danger font-weight-bold py-2 px-6"
              >{{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_TITLE") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_EDUCATION,
  GET_GENDER,
  GET_IDENTITY_TYPE,
  //SET_AUTH,
  // REGISTER,
  SET_EDUCATION,
  //SET_ERROR,
  // SET_ERROR,
  SET_GENDER,
  SET_IDENTITY_TYPE
  //, SET_LOADING_LOGIN, SET_SUCCESS_ACTION, SET_USER_INFO
} from "@/core/services/store/auth.module";
import KTCard from "@/view/content/Card.vue";
import { validationMixin } from "vuelidate";
import {email, required, minLength, sameAs} from "vuelidate/lib/validators";
import * as axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  name: "profile",
  data() {
    return {
      editmode : false,
      url: null,
      title: this.$t("MENU.PROFILE"),
      pathIdentity: "",
      pathProfile: "",

      form: {
        email: "",
        password: "",
        passwordconfirm: "",
        userName: "",
        fullName: "",
        birthPlace: "",
        birthday: "",
        gender: null,
        education: null,
        identityType: null,
        identityNo: "",
        identityFile: null,
        phone: "",
        address: null,
        idimage: null,
        profileimage: null,
      },
      logo: process.env.BASE_URL + "media/logos/komnasham.png",
      educations: [],
      gender: [],
      identityType: [],
      userInfo: null
    };
  },
  validations: {
    form: {
      userName: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      },
      passwordconfirm: {
        required,
        sameAsPassword: sameAs("password")
      },
      fullName: {
        required,
        minLength: minLength(5)
      },
      birthPlace: {
        required,
        minLength: minLength(4)
      },
      birthday: {
        required
      },
      education: {
        required
      },
      gender: {
        required
      },
      identityNo: {
        required
      },
      identityType: {
        required
      },
      phone: {
        required
      },
      address: {
        required,
        minLength: minLength(5)
      },
      idimage: {

      },
      profileimage: {

      }
    }
  },
  
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.PERSONAL_INFORMATION"),
        route: "profile"
      }
    ]);
    this.$store.dispatch(GET_EDUCATION);
    this.$store.dispatch(GET_GENDER);
    this.$store.dispatch(GET_IDENTITY_TYPE);
    this.bindingData();
    this.getUserData();
    console.log(this.pathIdentity);
  },
  methods: {
    getViewData(){
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("user/smartmapinfo")
            .then(({ response }) => {
              this.$v.form.email.$model = response.data[0].email;
              this.$v.form.fullName.$model = response.data[0].name;
              this.$v.form.identityNo.$model = response.data[0].identity_no;
              this.$v.form.birthPlace.$model = response.data[0].birth_place;
              this.$v.form.identityType.$model = response.data[0].identity_type_id;
              this.$v.form.birthday.$model = response.data[0].birth_date;
              this.$v.form.idimage.$model = response.data[0].identity_file;
              // this.$v.form.profileimage.$model = response.data[0].photo_file;
              this.$v.form.education.$model = response.data[0].education_id;
              this.$v.form.phone.$model = response.data[0].phone_no;
              this.$v.form.gender.$model = response.data[0].gender;
              this.$v.form.address.$model = response.data[0].address;

              this.pathIdentity = response.data[0].identity_file;
              resolve(response);
            })
            .catch(({ error }) => {
              reject(error);
            });
      });
    },
    getUserData() {
      axios({
        url:
            process.env.VUE_APP_API_BASE_URL + "api/user/smartmapinfo",
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + JwtService.getToken()
        }
      }).then((response) => {
        this.$v.form.email.$model = response.data.data[0].email;
        this.$v.form.fullName.$model = response.data.data[0].name;
        this.$v.form.identityNo.$model = response.data.data[0].identity_no;
        this.$v.form.birthPlace.$model = response.data.data[0].birth_place;
        this.$v.form.identityType.$model = response.data.data[0].identity_type_id;
        this.$v.form.birthday.$model = response.data.data[0].birth_date;
        this.$v.form.idimage.$model = response.data.data[0].identity_file;
        // this.$v.form.profileimage.$model = response.data.data[0].photo_file;
        this.$v.form.education.$model = response.data.data[0].education_id;
        this.$v.form.phone.$model = response.data.data[0].phone_no;
        this.$v.form.gender.$model = response.data.data[0].gender;
        this.$v.form.address.$model = response.data.data[0].address;

        this.pathIdentity = response.data.data[0].identity_file;
        this.url = this.createDownloadUrl(response.data.data[0].photo_file)
      }).catch(({ error }) => {
        this.userInfo = JSON.parse(window.localStorage.getItem("user_info"));
        if(this.userInfo != null){
          this.getParseData(this.userInfo);
        }else{
          console.log(error);
        }
      });
    },
    getParseData(response){
      this.$v.form.email.$model = response.data[0].email;
      this.$v.form.fullName.$model = response.data[0].name;
      this.$v.form.identityNo.$model = response.data[0].identity_no;
      this.$v.form.birthPlace.$model = response.data[0].birth_place;
      this.$v.form.identityType.$model = response.data[0].identity_type_id;
      this.$v.form.birthday.$model = response.data[0].birth_date;
      this.$v.form.idimage.$model = response.data[0].identity_file;
      // this.$v.form.profileimage.$model = response.data[0].photo_file;
      this.$v.form.education.$model = response.data[0].education_id;
      this.$v.form.phone.$model = response.data[0].phone_no;
      this.$v.form.gender.$model = response.data[0].gender;
      this.$v.form.address.$model = response.data[0].address;

      this.pathIdentity = response.data[0].identity_file;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    bindingData() {
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_EDUCATION) {
          this.educations = this.getEducations;
        }
        if (mutation.type === SET_GENDER) {
          this.gender = this.getGender;
        }
        if (mutation.type === SET_IDENTITY_TYPE) {
          this.identityType = this.getIdentityType;
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    goToEditmode() {
      this.editmode = true;
    },
    cancelEdit() {
      this.editmode = false;
    },
    goToChangePass() {
      this.$router.push("changePassword");
    },
    chooseFile(event) {
      let input = event.target.files[0];
      let validFormat = this.validate_fileupload(input);
      if (validFormat == false) {
          Swal.fire("Format File", "File format not supported", "error");
          return;
        }
    },
    validate_fileupload(fileName) {
      var allowed_extensions = new Array(
          "jpg",
          "png",
          "gif",
          "jpeg"
      );
      var file_extension = fileName.split(".").pop().toLowerCase();

      for (var i = 0; i <= allowed_extensions.length; i++) {
        if (allowed_extensions[i] == file_extension) {
          return true; // valid file extension
        }
      }
      return false;
    },
    createDownloadUrl(fileName) {
      // return (
      //     axios({
      //       url:
      //           process.env.VUE_APP_API_BASE_URL + "api/smartmap/download-profile/" + fileName,
      //       method: "GET",
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //         Authorization: 'Bearer ' + JwtService.getToken()
      //       }
      //     }).then(response => Buffer.from(response.data, 'binary').toString('base64'))
      // );
      return (
          process.env.VUE_APP_API_BASE_URL +
          "api/smartmap/download-profile/" +
          fileName
      );
    },
    onSubmit() {
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");


      let formEditProfile = new FormData();

      formEditProfile.set(
        "username",
        this.$v.form.userName.$model
      );
      formEditProfile.set("email", this.$v.form.email.$model);
      formEditProfile.set("name", this.$v.form.fullName.$model);
      formEditProfile.set(
        "birth_place",
        this.$v.form.birthPlace.$model
      );
      formEditProfile.set(
        "birth_date",
        this.$v.form.birthday.$model
      );
      formEditProfile.set(
        "education",
        this.$v.form.education.$model
      );
      formEditProfile.set(
        "identity_no",
        this.$v.form.identityNo.$model
      );
      formEditProfile.set(
        "identity_type",
        this.$v.form.identityType.$model
      );
      formEditProfile.set("phone_no", this.$v.form.phone.$model);
      formEditProfile.set("address", this.$v.form.address.$model);
      formEditProfile.set("gender", this.$v.form.gender.$model);
      formEditProfile.set(
        "password",
        this.$v.form.password.$model
      );
      formEditProfile.append(
        "identity_file",
        this.$v.form.idimage.$model
      );
      formEditProfile.append(
          "photo_pic",
          this.$v.form.profileimage.$model
      );
      axios({
        url:
            process.env.VUE_APP_API_BASE_URL + "api/smartmap/update_user",
        method: "POST",
        data : formEditProfile,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: 'Bearer ' + JwtService.getToken()
        }
      })
        .then(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          this.$refs["my-modal"].show();
          this.editmode = false;
        }).catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          this.$refs["my-modal2"].show();
        });;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.getUserData();
      return;
    },
  },
  components: {
    KTCard
  },
  computed: mapGetters([
    "isAuthenticated",
    "getEducations", 
    "getGender", 
    "getIdentityType"
  ])
};
</script>

